<template>
  <div
    class="page-content"
    :class="{
      'mise-en-page-bureau': estEcranBureau,
      'mise-en-page-mobile': !estEcranBureau
    }"
  >
    <div class="header">
      <h3 v-if="estEcranBureau">Gérer les utilisateurs</h3>
      <h5 class="mt-2" v-if="!estEcranBureau">Gérer les utilisateurs</h5>
      <b-button
        v-if="
          currentUser &&
            currentUser.type != 'user.final' &&
            selectedMenu == 'users'
        "
        size="sm"
        variant="success"
        @click.stop="handleClickBtnAddUsers()"
        v-b-modal.addUserModal
      >
        <font-awesome-icon icon="plus" />
        <span class=""> Ajouter un utilisateur</span>
      </b-button>
      <b-button
        class="ml-3"
        v-if="
          currentUser && currentUser.isSuper && selectedMenu == 'permission'
        "
        @click.stop="handleClickBtnAddPermission()"
        size="sm"
        variant="success"
        v-b-modal.addPermissionModal
      >
        <font-awesome-icon icon="plus" />
        <span class="">Ajouter un ensemble d'autorisations</span>
      </b-button>
    </div>
    <div class="blockMenu">
      <div @click="clickMenu('users')">
        <router-link class="menu-item" to="/setting/users">
          Utilisateurs
        </router-link>
      </div>
      <div
        @click="clickMenu('permission')"
        v-if="currentUser && currentUser.isSuper"
      >
        <router-link class="menu-item" to="/setting/users/permissions">
          Ensembles d'autorisations
        </router-link>
      </div>
      <div
        @click="clickMenu('corbeille')"
        v-if="currentUser && currentUser.isSuper"
      >
        <router-link class="menu-item" to="/setting/users/corbeilles">
          Corbeille
        </router-link>
      </div>
      <div
        @click="clickMenu('history-users')"
        v-if="currentUser && currentUser.isSuper"
      >
        <router-link
          class="menu-item"
          to="/setting/users/historique-utilisateurs"
        >
          Historiques
        </router-link>
      </div>
    </div>
    <hr class="lineMenu" />
    <div class="content">
      <router-view ref="sortUsersView" />
    </div>
    <!-- users -->
    <b-modal
      id="addUserModal"
      :title="
        currentUser && currentUser.type == 'user.commercial'
          ? ' Ajouter un utilisateur final'
          : 'Ajouter un utilisateur'
      "
      no-close-on-backdrop
      :hide-footer="true"
      ref="addUserModal"
      @show="resetModal"
      @hidden="resetModal(true)"
    >
      <form ref="newUserForm" @submit.prevent="handleSubmitUsers">
        <b-form-group label="Prénom" label-for="firstname-input">
          <b-form-input
            id="firstname-input"
            v-model="userToAdd.first_name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Nom" label-for="lastname-input">
          <b-form-input
            id="lastname-input"
            v-model="userToAdd.last_name"
          ></b-form-input>
        </b-form-group>
        <!-- <b-form-group
          v-if="currentUser && currentUser.isSuper"
          label="Pipedrive api token"
          label-for="api-token-input"
        >
          <b-form-input
            id="api-token-input"
            v-model="userToAdd.api_pipe_token"
          ></b-form-input>
        </b-form-group> -->
        <b-form-group label="E-mail" label-for="email-input">
          <b-form-input
            id="email-input"
            v-model="userToAdd.email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="currentUser && currentUser.isSuper"
          label="Téléphone"
          label-for="phone-input"
        >
          <b-form-input
            id="phone-input"
            v-model="userToAdd.phone"
            type="tel"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Mot de passe" label-for="password-input">
          <b-form-input
            id="pasword-input"
            type="password"
            v-model="userToAdd.password"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirmation de mot de passe"
          label-for="password-confirmation-input"
        >
          <b-form-input
            id="pasword-confirmation-input"
            type="password"
            v-model="userToAdd.password_confirmation"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="currentUser && currentUser.isSuper"
          label="Code postal"
          label-for="postal-code-input"
        >
          <b-form-input
            id="postal-code-input"
            type="text"
            v-model="userToAdd.zipcode"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Type"
          label-for="type-input"
          v-if="currentUser && currentUser.isSuper"
        >
          <b-form-select
            id="type-input"
            v-model="userToAdd.type"
            required
            @input="typeUsers"
          >
            <b-form-select-option-group
              v-for="(group, index) in usertypes"
              :key="index"
              :label="group.groupe"
            >
              <b-form-select-option
                v-for="type in group.types"
                :key="'types' + type.id"
                :value="type.id"
                required
              >
                {{ type.name }}</b-form-select-option
              >
            </b-form-select-option-group>
          </b-form-select>
        </b-form-group>
        <div class="visibility-export-import">
          <div class="switch" v-if="userToAdd.type">
            <div>
              <b-form-group label="Export Excel"></b-form-group>
            </div>
            <div class="etat-switch">
              <b-form-checkbox
                v-if="userToAdd.type == 'user.sub-super-admin'"
                switch
                v-model="checkedExport"
                :disabled="userToAdd.type == 'user.sub-super-admin'"
              ></b-form-checkbox>
              <b-form-checkbox
                v-else
                switch
                v-model="userToAdd.has_export_excel"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </div>
          </div>
          <div class="switch" v-if="userToAdd.type">
            <div>
              <b-form-group label="Import Excel"></b-form-group>
            </div>
            <div class="etat-switch">
              <b-form-checkbox
                switch
                v-model="userToAdd.has_import_excel"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
        <!-- USERS INTERNE /EXTERNE -->
        <div
          v-if="currentUser && currentUser.isSuper"
          class="switch-interne"
          :class="{ 'switch-interne-type': userToAdd.type }"
        >
          <div>
            <b-form-group label="Interne"></b-form-group>
          </div>
          <div class="etat-switch">
            <b-form-checkbox
              switch
              v-model="userToAdd.can_edit_fiscal"
              :value="1"
              :unchecked-value="0"
            ></b-form-checkbox>
          </div>
        </div>
        <b-form-group
          v-if="
            computedRegie &&
              currentUser.type != 'user.commercial' &&
              currentUser.type != 'user.final'
          "
          label="Type"
          label-for="type-input"
        >
          <b-form-select id="type-input" v-model="userToAdd.typeRegie" required
            ><option v-for="type in usertypes" :key="type.text">{{
              type.text
            }}</option></b-form-select
          >
        </b-form-group>

        <b-form-group
          v-if="
            userToAdd.type == 'user.final' ||
              userToAdd.typeRegie == 'Utilisateur Final'
          "
          label="Supérieur"
          label-for="superieur-input"
        >
          <b-form-select
            v-if="
              listCommercailsUser &&
                listCommercailsUser.data &&
                listCommercailsUser.data.length
            "
            id="superieur-input"
            v-model="userToAdd.superior_id"
            required
          >
            <option :value="null" disabled
              >-- Sélectionner un Supérieur --</option
            >
            <option
              v-for="item in listCommercailsUser.data"
              :key="'superior_id ' + item.id"
              :value="item.id"
            >
              {{ item.first_name }} {{ item.last_name }}
            </option>
          </b-form-select>
          <b-form-select
            v-else
            id="superieur-input"
            v-model="userToAdd.superior_id"
            required
          >
            <option :value="null" disabled>-- Aucun Supérieur --</option>
            <option
              v-for="item in listCommercailsUser.data"
              :key="'superior_id ' + item.id"
              :value="item.id"
              required
            >
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="userToAdd.type == 'user.technicien'"
          label="Co-amo"
          label-for="Co_technicien-input"
        >
          <b-form-select
            id="Co_technicien-input"
            v-model="userToAdd.co_technician_id"
          >
            <option
              v-for="item in [
                { id: null, label: '' },
                ...getProjectsUsersTechniciens
              ]"
              :key="'co_technician ' + item.id"
              :value="item.id"
            >
              {{ item.full_name }}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <b-form-group
          v-if="userToAdd.type == 'user.technicien'"
          label="Techniciens dans pipedrive"
          label-for="technicien-input"
        >
          <b-form-select
            id="technicien-input"
            v-model="userToAdd.technician_id_pipe"
          >
            <option
              v-for="item in technicianspipedrive"
              :key="'technician_pipe-index-' + item.id_tech_pipe"
              :value="item.id_tech_pipe"
            >
              {{ item.label }}
            </option>
          </b-form-select>
        </b-form-group> -->
        <!-- <b-form-group
          v-if="userToAdd.type == 'user.commercial.ite'"
          label="Commerciaux dans Pipedrive"
          label-for="commercial-input"
        >
          <b-form-select
            id="commercial-input"
            v-model="userToAdd.commercial_ite_id_pipe"
          >
            <option
              v-for="item in commerciauxpipedrive"
              :key="'commercial_ite_id_pipe ' + item.id_comm_ite_pipe"
              :value="item.id_comm_ite_pipe"
            >
              {{ item.label }}
            </option>
          </b-form-select>
        </b-form-group> -->

        <b-form-group
          v-if="
            userToAdd.type == 'user.commercial' ||
              userToAdd.typeRegie == 'Commercial'
          "
          label="Commission 101"
          label-for="commission101-input"
        >
          <b-form-input
            type="number"
            id="commission101-input"
            v-model="userToAdd.coeff_101"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="
            userToAdd.type == 'user.commercial' ||
              userToAdd.typeRegie == 'Commercial'
          "
          label="Commission 102"
          label-for="commission102-input"
        >
          <b-form-input
            type="number"
            id="commission102-input"
            v-model="userToAdd.coeff_102"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="
            userToAdd.type == 'user.commercial' ||
              userToAdd.typeRegie == 'Commercial'
          "
          label="Commission 103"
          label-for="commission103-input"
        >
          <b-form-input
            type="number"
            id="commission103-input"
            v-model="userToAdd.coeff_103"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="
            currentUser &&
              currentUser.isSuper &&
              (userToAdd.type == 'user.commercial' ||
                userToAdd.type == 'user.final')
          "
          label="Proprietaire"
          label-for="owner-input"
        >
          <b-form-select id="owner-input" v-model="userToAdd.owner_id">
            <option></option>
            <option
              v-for="item in property"
              :key="'addProperty' + item.id"
              :value="item.id"
              >{{ item.full_name }}</option
            >
          </b-form-select>
        </b-form-group>
        <!-- <b-form-group
          v-if="
            userToAdd.type == 'user.admin' ||
              userToAdd.type == 'user.agent' ||
              userToAdd.type == 'user.resp.plan' ||
              userToAdd.type == 'user.administration'
          "
          label="Utilisateur pipedrive "
          label-for="owner-input"
        >
          <b-form-select id="owner-input" v-model="userToAdd.pipe_user_id">
            <option
              v-for="item in [
                { id: null, label: '' },
                ...usersPipedriveNoSelectionne
              ]"
              :key="'pipe_user_id' + item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </b-form-select>
        </b-form-group> -->

        <div class="message">
          <div v-if="loading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('addUserModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Valider
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- permission -->
    <b-modal
      id="addPermissionModal"
      ref="addPermissionModal"
      title="Ajouter un ensemble d'autorisations"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal()"
      no-close-on-backdrop
    >
      <form @submit.prevent="handleSubmitPermission">
        <b-form-group label="Nom" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="permissionToAdd.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Utilisateurs" label-for="users-input">
          <multiselect
            v-model="permissionToAdd.users"
            :options="getListeUsers"
            placeholder="Rechercher l'utilisateur"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="full_name"
            track-by="id"
            :preselect-first="false"
          >
          </multiselect>
        </b-form-group>

        <b-form-group
          label="Autorisations"
          label-for="permission-input"
          v-if="getListePermission && getListePermission.length"
        >
          <div class="list-permission">
            <b-form-checkbox
              v-for="item in getListePermission"
              :key="'permission ' + item.id"
              :value="item.id"
              v-model="permissionToAdd.permission"
              switch
            >
              {{ item.name }}
            </b-form-checkbox>
          </div>
        </b-form-group>
        <div class="message">
          <div v-if="getPermissionLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getPermissionError" class="error">
            <ul v-if="Array.isArray(getPermissionError)">
              <li v-for="(e, index) in getPermissionError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getPermissionError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('addPermissionModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'users',
  data() {
    return {
      selectedMenu: 'users',
      permissionToAdd: {
        name: null,
        users: [],
        permission: []
      },
      userToAdd: {
        first_name: null,
        last_name: null,
        last_name_update: null,
        first_name_update: null,
        email: null,
        api_pipe_token: null,
        password: null,
        password_confirmation: null,
        type: '',
        typeRegie: '',
        superior_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        co_technician_id: null,
        technician_id_pipe: null,
        commercial_ite_id_pipe: null,
        pipe_user_id: null,
        owner_id: null,
        phone: null,
        zipcode: null,
        has_export_excel: 0,
        has_import_excel: 0,
        can_edit_fiscal: 0
      },
      checkedExport: true,
      loading: false,
      initLoading: true,
      error: null,
      estEcranBureau: true
    }
  },
  methods: {
    ...mapActions([
      'addUser',
      'getlistCommercailsUser',
      'getTechnicianspipedrive',
      'getUsersPipedrive',
      'getCommerciauxPipedrive',
      'addPermisssionSet',
      'resetErrorPermission',
      'fetchUsersTechniciens',
      'fetchProprieteRegie'
    ]),
    onResize() {
      this.estEcranBureau = window.innerWidth >= 827 // ajustez le point de rupture selon vos besoins
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    resetModal() {
      this.permissionToAdd = {
        name: null,
        users: [],
        permission: []
      }
      this.resetErrorPermission()
      this.userToAdd = {
        first_name: null,
        last_name: null,
        last_name_update: null,
        first_name_update: null,
        email: null,
        api_pipe_token: null,
        password: null,
        password_confirmation: null,
        type: '',
        typeRegie: '',
        superior_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        co_technician_id: null,
        technician_id_pipe: null,
        commercial_ite_id_pipe: null,
        pipe_user_id: null,
        owner_id: null,
        phone: null,
        zipcode: null,
        has_export_excel: 0,
        has_import_excel: 0,
        can_edit_fiscal: 0
      }
      this.loading = false
      this.error = null
    },
    typeUsers() {
      this.userToAdd.has_export_excel = 0
      this.userToAdd.has_import_excel = 0
      this.userToAdd.can_edit_fiscal = 0
    },
    async handleSubmitUsers() {
      this.loading = true
      this.error = null
      if (this.userToAdd.password != this.userToAdd.password_confirmation) {
        this.loading = false
        this.error = [
          {
            password:
              'le mot de passe et la confirmation du mot de passe doivent être égaux'
          }
        ]
        return
      }
      if (this.userToAdd.password.length < 6) {
        this.loading = false
        this.error = [
          { password: 'le mot de passe doit contenir au moins 6 caractères' }
        ]
        return
      }
      if (this.computedRegie) {
        if (this.userToAdd.typeRegie == 'Commercial') {
          this.userToAdd.type = 'user.commercial'
        } else {
          this.userToAdd.type = 'user.final'
        }
      }
      const response = await this.addUser(this.userToAdd)
      if (response.success) {
        this.loading = false
        this.hideModal('addUserModal')
        this.getlistCommercailsUser()
        this.getTechnicianspipedrive()
        this.getUsersPipedrive()
        this.getCommerciauxPipedrive()
        this.fetchProprieteRegie({
          exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
        })
        this.fetchUsersTechniciens()
        if (
          this.$refs['sortUsersView'] &&
          typeof this.$refs['sortUsersView'].SortUsers == 'function'
        ) {
          this.$refs['sortUsersView'].SortUsers()
        }
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleClickBtnAddPermission() {
      this.$refs['addPermissionModal'].show()
    },
    async handleClickBtnAddUsers() {
      this.$refs['addUserModal'].show()
      this.fetchProprieteRegie({
        exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
      })
    },
    clickMenu(menu) {
      this.selectedMenu = menu
    },
    async handleSubmitPermission() {
      const payload = {
        name: this.permissionToAdd.name,
        users: this.permissionToAdd.users.map(user => {
          return user.id
        }),
        permission: [...this.permissionToAdd.permission]
      }
      const response = await this.addPermisssionSet(payload)
      if (response) {
        this.hideModal('addPermissionModal')
      }
    }
  },
  computed: {
    ...mapGetters([
      'usertypes',
      'listCommercailsUser',
      'technicianspipedrive',
      'commerciauxpipedrive',
      'usersPipedriveNoSelectionne',
      'getListePermission',
      'getListeUsers',
      'getPermissionLoading',
      'getPermissionError',
      'getProjectsUsersTechniciens',
      'property',
      'currentUser'
    ]),
    computedRegie: function() {
      return (
        this.currentUser &&
        this.currentUser.type == 'user.admin' &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.type === 'regie'
      )
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
    if (this.$route.path === '/setting/users/permissions') {
      this.selectedMenu = 'permission'
    } else if (this.$route.path === '/setting/users/corbeilles') {
      this.selectedMenu = 'corbeille'
    } else if (this.$route.path === '/setting/users/historique-utilisateurs') {
      this.selectedMenu = 'history-users'
    } else {
      this.selectedMenu = 'users'
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    .color {
      color: #2dabe2;
    }
  }
  .blockMenu {
    display: flex;
    margin-top: 15px;
    .menu-item {
      text-decoration: none;
      color: #26292c;
      padding: 10px;
      &.router-link-exact-active {
        color: #2dabe2;
        border-bottom: 2px solid #2dabe2;
      }
    }
  }
  .lineMenu {
    margin-top: 8px;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 765px) {
    .blockMenu {
      display: block;
      .menu-item {
        padding: 0px;
      }
    }
  }
}
#addPermissionModal {
  .list-permission {
    position: relative;
    height: 100px;
    overflow: auto;
  }
}
#addUserModal {
  .visibility-export-import {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: -8px;
    .switch {
      display: flex;
      .etat-switch {
        margin-left: 25px;
        margin-top: -2px;
      }
    }
    .custom-switch
      .custom-control-input:disabled:checked
      ~ .custom-control-label::before {
      color: #fff;
      border-color: #08a742;
      background-color: #08a742;
    }
  }
  .switch-interne {
    display: flex;
    margin: 8px 0px -3px 1px;

    &.switch-interne-type {
      margin-left: 79px;
    }
    .etat-switch {
      margin-left: 25px;
      margin-top: -2px;
    }
  }
}
</style>
<style lang="scss">
#addUserModal {
  .switch {
    .custom-switch
      .custom-control-input:disabled:checked
      ~ .custom-control-label::before {
      color: #fff;
      border-color: #08a742;
      background-color: #08a742;
    }
  }
}
</style>
